<template>
  <div class="wrapper">
    <div v-if="!isEmpty(chatroom)" class="d-flex chat-rooms" :style="isFeed ? '' : 'height: 100%'">
      <ion-card class="chat-room-card mx-0 my-0 py-3 px-3 clickable-item">
        <a @click.prevent="goToChat(chatroom)">
          <div class="d-flex" :class="isFeed ? 'flex-column' : 'align-items-center'">
            <!-- <div
              v-if="isOwner"
              class="clickable drop-down d-flex align-items-center justify-content-center position-absolute"
              @click.stop.prevent="openDropDown"
            >
              <i class="ti-more-alt" />
            </div> -->
            <div class="images" :class="{ 'w-100 position-relative': isFeed }">
              <ion-button
                :style="isFeed ? 'font-size: 8px;' : ''"
                v-if="status && isFeed"
                class="chatroom-btn"
                style="--background: #00b4c5; position: absolute; right: 1px; top: -4px"
              >
                <div class="d-flex align-items-center">
                  <ion-icon class="icon" :icon="checkmarkCircleOutline" />
                  <div :style="isFeed ? 'font-size: 9px' : ''" class="text mx-1">Joined</div>
                </div>
              </ion-button>
              <div class="bk" :class="{ 'w-100': isFeed }">
                <img class="bk-img" v-character-card-image :src="chatroom?.world?.cover_image" />
                <div class="pp position-absolute">
                  <img v-character-card-image class="img" :src="getThumbnail(chatroom.banner_img_url)" />
                </div>
              </div>
            </div>

            <div :class="isFeed ? 'mt-2' : 'ml-3 chat-details'" class="flex-grow-1 text-black">
              <div class="d-flex">
                <div class="flex-grow-1 d-flex mr-1">
                  <div class="title text-black d-flex align-items-center justify-content-start">
                    {{ chatroom.title }}
                  </div>
                  <div v-if="muted" class="mx-1" fill="none">
                    <ion-icon :icon="volumeMuteOutline" style="font-size: 20px" color="medium" />
                  </div>
                </div>

                <div class="ml-auto">
                  <ion-badge :color="muted ? 'medium' : 'danger'">
                    <span v-if="unreadchat >= 1"> {{ unreadchat }}</span>
                  </ion-badge>
                </div>
              </div>

              <div v-if="!['social-space-details', 'user-spaces'].includes(route.name)">
                <small>{{
                  isFeed ? truncateText(stripHtmlTags(get(chatroom, 'world.name')), 80) : get(chatroom, 'world.name')
                }}</small>
              </div>

              <div class="d-flex buttons" :class="{ 'flex-column small-btn': isMobSmallScreen }">
                <ion-button
                  :style="isFeed ? 'font-size: 8px; margin-bottom: 2px !important' : ''"
                  v-if="status && !isFeed"
                  class="chatroom-btn"
                  style="--background: #00b4c5"
                >
                  <div class="d-flex align-items-center">
                    <ion-icon class="icon" :icon="checkmarkCircleOutline" />
                    <div :style="isFeed ? 'font-size: 9px' : ''" class="text mx-1">Joined</div>
                  </div>
                </ion-button>
                <ion-button
                  :style="isFeed ? 'font-size: 8px; margin-top: 2px !important' : ''"
                  v-if="chatroom.privacy === 'private' || get(chatroom, 'world.privacy') === 'O'"
                  :class="chatroom.privacy === 'private' ? 'private-chatroom-button' : 'chatroom-btn'"
                >
                  <div class="d-flex align-items-center">
                    <i v-if="chatroom.privacy === 'private'" class="icon ti-lock" />
                    <ion-icon v-else class="icon" :icon="peopleOutline" />
                    <div :style="isFeed ? 'font-size: 9px' : ''" class="text mx-1">{{ chatroom.privacy }} chatroom</div>
                  </div>
                </ion-button>
              </div>
              <MatureTag v-if="isNsfw" class="mb-1" size="md" />
              <div class="description">
                {{
                  isFeed
                    ? truncateText(stripHtmlTags(chatroom.description), 60)
                    : truncateText(stripHtmlTags(chatroom.description), 120)
                }}
              </div>
              <div class="lower d-flex mt-1" :class="isFeed ? 'justify-content-between' : 'align-items-center'">
                <div class="d-flex align-items-center">
                  <strong>
                    <ion-icon :style="isFeed ? 'font-size: 14px' : ''" class="member-icon mt-1" :icon="peopleOutline"
                  /></strong>
                  <div :style="isFeed ? 'font-size: 10px' : ''" class="ml-1">
                    {{ isChatroom ? chatroom.member_count : memberCount }}
                  </div>
                </div>

                <div
                  v-if="lastmsg && isLastMessageLessThan24"
                  class="d-flex"
                  :class="isFeed ? 'align-items-center pl-1' : 'pl-2'"
                  :style="isFeed ? 'font-size: 10px; text-wrap: nowrap' : ''"
                >
                  <div><strong>Last Message: </strong></div>
                  <div v-if="lastmsg" class="mx-1">{{ formatTimeAgo(lastmsg) }}</div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </ion-card>
    </div>
    <div v-else class="no-data">No Chatrooms here yet</div>
  </div>
</template>

<script lang="ts" setup>
import { checkmarkCircleOutline, peopleOutline, volumeMuteOutline } from 'ionicons/icons';
import { formatTimeAgo } from '@/shared/utils/dateTime';
import MatureTag from '@/shared/components/MatureTag.vue';
import { stripHtmlTags } from '@/shared/utils/string';
import { resizeUpload } from '@/shared/utils/upload';
import { truncateText } from '@/shared/utils/string';
import { isMobileFn } from '@/apps/mobile/native/device';

const props = defineProps({
  chatroom: {
    type: Object,
    default: {},
  },
  status: {
    type: Boolean,
    default: false,
  },
  memberCount: {
    type: Number,
  },
  isChatroom: {
    type: Boolean,
    default: false,
  },
  lastmsg: {
    type: String,
    default: null,
  },
  unreadchat: {
    type: Number,
    default: 0,
  },
  muted: {
    type: Boolean,
    default: false,
  },
  isFeed: {
    type: Boolean,
    default: false,
  },
  openBlank: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();
const route = useRoute();
const chatroom = toRef(props, 'chatroom');
const status = toRef(props, 'status');
const isFeed = toRef(props, 'isFeed');
const openBlank = toRef(props, 'openBlank');
const lastmsg = toRef(props, 'lastmsg');
const { isMobSmallScreen, width } = useWindowSize();

const isNsfw = computed(() => chatroom.value?.world?.is_nsfw);

const getThumbnail = (url: string) => {
  if (!url) return;
  return resizeUpload(url, '80x80');
};

// const openDropDown = async (ev: CustomEvent) => {
//   popovers.open(ev, CardPopover, { title: 'Chatroom', id: id.value, value: 'chatroom' });
// };
const isLastMessageLessThan24 = computed(() => {
  const lastMsgDate: any = new Date(lastmsg.value);
  const currentDate: any = new Date();
  const timeDifference = currentDate - lastMsgDate;
  const hoursDifference = timeDifference / (1000 * 60 * 60);
  return hoursDifference < 24;
});
const routerName = computed(() => {
  return !status.value && chatroom.value.privacy === 'private' ? 'chatroom-details' : 'social-space-chatroom';
});

const goToChat = async (chatroom: any) => {
  const route = { name: routerName.value, params: { id: chatroom.id } };

  const isMobile = await isMobileFn();

  if (openBlank.value && !isMobile) {
    const { href } = router.resolve(route);
    window.open(href, '_blank');
    return;
  }

  router.push(route);
};
</script>

<style lang="sass" scoped>
.drop-down
  position: absolute
  z-index: 2
  right: 16px
  width: 18px
  height: 18px
  top: 17px
  color: #fff
  background: #0A0928E5
  font-size: 12px
  border-radius: 6px
.lower
  @media(max-width: 390px)
    flex-direction: column
    align-items: flex-start !important
    .pl-3
      padding-left: 0 !important
.images
  align-self: baseline
  .bk
    width: 82px
    height: 120px
    display: flex
    align-items: center
    justify-content: center
    background: #d1abf5
    border-radius: 12px
    overflow: hidden
    .bk-img
      object-fit: cover
      width: 100%
      height: 100%
      opacity: 0.3
.buttons
  margin: 0
  grid-gap: 6px
  @media(max-width: 500px)
    margin: 0 0 6px 0 !important
    grid-gap: 0px
.text-color
  color: gray
.chatroom-btn
  --border-radius: 15px
  height: 20px
  margin-left: 0
  margin-right: 0
  @media(max-width: 500px)
    margin: 6px 0 !important


.private-chatroom-button
  --background: #341e7a
  --border-radius: 15px
  height: 20px

.text
  font-size: 12px

.social-space-chatrooms
  overflow-x: auto
.section-title
  font-size: 36px
  font-weight: bold
  margin-bottom: 12px
  margin-top: 30px
.title
  font-size: 20px
  font-weight: bold
  line-height: 16px

.description
  font-size: 14px

.member-icon
  font-size: 16px
  font-weight: bold

.icon
  font-size: 12px
  font-weight: bold

.chat-room-card
  box-shadow: unset !important
  overflow: unset

  @media(min-width: 420px)
    min-width: 100% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 100% !important
    max-width: 50% !important
  .pp
    width: 65px !important
    height: 65px !important
    background: #f178b9
    display: flex
    align-items: center
    justify-content: center
    border-radius: 35px
  .img
    &:not(.mod-small-img)
      width: 65px !important
      height: 65px !important
      border-radius: 35px
      border: solid gray 0.1px
      object-fit: cover
      align-self: baseline
    @media(max-width: 500px)
      &:not(.mod-small-img)
        width: 60px !important
        height: 60px !important
        border-radius: 30px

  .chat-details
    padding-top: 5px
    min-height: 120px
    max-width: calc( 100% - 82px )

    @media(max-width: 500px)
      max-width: calc( 100% - 76px )
      word-break: break-word

.chat-rooms
  overflow-x: auto
  overflow: unset
.chat-rooms::-webkit-scrollbar
  display: none
.chat-rooms
  -ms-overflow-style: none
  scrollbar-width: none

@media(max-width: 318px)
  .small-btn
    width: calc(100% - 29px) !important
  .title
    width: calc(100% - 42px)
</style>
